import React from 'react';

import ProductPageLayout from '../components/ProductPageLayout';
import faq from '../faq/strangerthings';
import Terms from '../termsOfService/strangerthings.mdx';

const SecondPage = () => (
  <ProductPageLayout
    title="Stranger Things Intro Creator"
    emailAlias="StrangerThings"
    website="https://strangerthingsintrocreator.kassellabs.io"
    faq={faq}
    terms={<Terms />}
  />
);

export default SecondPage;

import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="h2" components={components}>{`7. Stranger Things Intro Creator`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`7.1.`}</MDXTag>{` This website is not related or to Netflix. The music and the Stranger Things logo are copyrights of Netflix.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`8. Video customizations`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`8.1.`}</MDXTag>{` To receive the video earlier the payment must be in the exact value or greater than `}<MDXTag name="strong" components={components} parentName="p">{`10 US Dollars`}</MDXTag>{`.
The video will be in Full HD quality with the resolution (1920x900).`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`8.2.`}</MDXTag>{` You can see a sample of a video rendered here: `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://youtu.be/Q0eEXKyA540"
        }}>{`https://youtu.be/Q0eEXKyA540`}</MDXTag></MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      
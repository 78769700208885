import React from 'react';
import { Accordion } from 'semantic-ui-react';

export default [
  {
    title: 'My final video has line breaks that are incorrect, is possible to fix it?',
    content: (
      <Accordion.Content>
        <p>
          This happens due to the difference between the video and your screen resolution.
          We fix it by changing the height of the video so it can match your previewed version.
        </p>
        <p>Please contact us so we can fix it for you.</p>
      </Accordion.Content>
    ),
  },
  {
    title: 'How the rendered video will look like?',
    content: (
      <Accordion.Content>
        <p>
          The videos are rendered on Full HD resolution (1920 x 900).
          The video file is in MP4 format and you should have
          a video player and a device that is capable to reproduce this media format.
        </p>
        <p>
          We do not guarantee that the rendered videos are identical
          to the creations in this service.
          The quality may differ depending on the device on which the content is displayed.
        </p>
        <p>
          You can see a sample of a video rendered here:
          {' '}
          <a href="https://youtu.be/Q0eEXKyA540">https://youtu.be/Q0eEXKyA540</a>
        </p>
      </Accordion.Content>
    ),
  },
];
